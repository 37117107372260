import React, { useState } from "react";
import Header from "../shared/Header";
import Layout from "../shared/Layout";
import islandImg from "../images/islandimg.png";
import islandImg1 from "../images/islandimg1.png";
import {
  Section,
  Content,
  Col,
  H2,
  Line,
  H3,
  P,
  Btn,
  Arrow,
} from "../styles/shared";
import styled from "styled-components";
import moto from "../images/moto.jpg";
import cousine from "../images/cousine.jpg";
import zamek from "../images/zamek.jpg";
import vid from "../images/vid.mp4";
import Logo from "../images/logo.png";
import { MdOutlineEmail } from "react-icons/md";
import Footer from "../shared/Footer";
import MapBox from "../shared/Map";
import air from "../images/other/air.jpg";
import island from "../images/island.png";
import Menu from "../shared/Menu";
import redirect from "../shared/Redirect";
import { Helmet } from "react-helmet";

//#region STYLES
const Grid3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  padding: 0 100px;
  @media (max-width: 768px) {
    padding: 0 10%;
    grid-template-columns: 1fr;
  }
`;

//#endregion

export default function Island() {
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      <Helmet>
        <title>Island - La Villa Bleue</title>
      </Helmet>
      {!open ? (
        <>
          <Header
            bg={islandImg}
            sbg={islandImg1}
            type="island"
            setOpen={setOpen}
          />
          <Section bg="#fff">
            <Content
              style={{
                backgroundImage: `url(${island})`,
                backgroundSize: "cover",
              }}
            >
              <Col align="end">
                <H2 align="right">
                  <span>A PLACE LIKE NO OTHER</span>
                  <Line align="end" />
                </H2>
                <H3 align="right">EXPERIENCE THE ISLAND'S ENCHANTMENT</H3>
                <Col align="end">
                  <P color="rgb(123,116,110)" align="right">
                    The island of Hvar is simply enchanting to all the senses,
                    offering a completely immersive Mediterranean experience
                    like no other. 2,700 sunshine hours each year make the
                    island the sunniest in Europe. The crystal clear, azure
                    Adriatic sea laps against the island’s sun-kissed pebble
                    beaches, offering space for unparalleled relaxation.
                    Venetian architecture embodies the island’s glorious
                    cultural past. Famed for its fragrances of lavender and
                    outstanding natural beauty, Hvar magnetically draws you in
                    closer with every step.
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    Hvar’s iconic Riva is one of the most exclusive on the
                    Adriatic coast, and only an hour's drive away from the
                    villa. Spend a day trip exploring the surrounding area that
                    boasts exquisite Mediterranean restaurants and wine bars
                    famed for their excellence in both local and international
                    specialities.
                  </P>
                  <P color="rgb(123,116,110)" align="right">
                    The island is a favourite hideaway destination for many
                    celebrities – Beyoncé, Tom Cruise, Prince Harry, Bono, Tom
                    Cruise, Jodie Foster, Kevin Spacey, Keira Knightley, Giorgio
                    Armani, and Roman Abramovich (to name just a few) have all
                    been known to frequent these shores. However, despite Hvar’s
                    growing popularity, the island maintains its traditions,
                    tranquility, and mystique. And at La Villa Bleue, you can
                    enjoy Hvar island in utmost peace and comfort as you become
                    enchanted by the jewel of the Adriatic.
                  </P>
                </Col>
                <Btn
                  border="1px solid #f5a913"
                  color="#7b746e"
                  width="358px"
                  hbgcolor="#f5a913"
                  hcolor="#fff"
                  style={{ marginTop: "30px" }}
                  onClick={() => redirect("/booking")}
                >
                  <span>REQUEST TO BOOK</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section bg="#fff">
            <Grid3>
              <div style={{ backgroundColor: "rgb(238,236,234)" }}>
                <img src={cousine} style={{ width: "100%", height: "280px" }} />
                <div style={{ padding: "25px" }}>
                  <H3 align="center">CUISINE</H3>
                  <Line align="center" />
                  <Col align="stretch">
                    <P align="center" color="rgb(123,116,110)">
                      Hvar’s wine tradition dates back 2,400 years, with its
                      vineyards soaking up the sunshine to produce not just
                      wine, but an intangible part of the cultural heritage. The
                      island’s olive oil, too, is amongst the best in the world.{" "}
                    </P>
                    <P align="center" color="rgb(123,116,110)">
                      The island's restaurants offer simply an exquisite
                      gastronomic experience, from local specialties like peka
                      and gregada, to wider cuisine. The Mediterranean diet is
                      known for its healing and life-extending benefits, but you
                      really must taste the island’s fresh cuisine to realise
                      just how delicious all that healthiness can be!
                    </P>
                  </Col>
                </div>
              </div>
              <div style={{ backgroundColor: "rgb(238,236,234)" }}>
                <img src={zamek} style={{ width: "100%", height: "280px" }} />
                <div style={{ padding: "25px" }}>
                  <H3 align="center">CULTURE</H3>
                  <Line align="center" />
                  <Col align="stretch">
                    <P align="center" color="rgb(123,116,110)">
                      Rich, Venetian architecture meets preserved cultural
                      heritage on Hvar, where you will find the oldest public
                      theatre in Europe, built in 1612, that is still active
                      today. The island’s Franciscan Monastery is even older,
                      dating back to 1465, with eco-ethno villages offering a
                      window into the past, untouched by the modern world.
                    </P>
                    <P align="center" color="rgb(123,116,110)">
                      Other cultural locations include Veneranda, Hanibal Lucic
                      garden, and the Spanish Fortress where you will find
                      breathtaking views of the town and the nearby Pakleni
                      islands.
                    </P>
                  </Col>
                </div>
              </div>
              <div style={{ backgroundColor: "rgb(238,236,234)" }}>
                <img src={moto} style={{ width: "100%", height: "280px" }} />
                <div style={{ padding: "25px" }}>
                  <H3 align="center">CUISINE</H3>
                  <Line align="center" />
                  <Col align="stretch">
                    <P align="center" color="rgb(123,116,110)">
                      Hvar offers a truly world-class range of activities.
                    </P>
                    <P align="center" color="rgb(123,116,110)">
                      Enjoy the water? There’s sailing, kayaking, scuba diving,
                      fishing, and much more.
                    </P>
                    <P align="center" color="rgb(123,116,110)">
                      Prefer to stay on land? Explore the numerous hiking
                      trails, biking routes, and rock climbing.
                    </P>
                    <P align="center" color="rgb(123,116,110)">
                      Not so adventurous? Try a wine or olive oil tasting tour,
                      or check out the art galleries and museums.
                    </P>
                    <P align="center" color="rgb(123,116,110)">
                      <strong>Hvar it your way!</strong>
                    </P>
                  </Col>
                </div>
              </div>
            </Grid3>
          </Section>
          <Section bg="#fff">
            <Content>
              <Col align="center">
                <H2 align="center">
                  DEDICATED TO <Line align="center" />
                </H2>
                <H3 align="center">A SENSATIONAL STAY</H3>
                <Col align="center">
                  <P align="center" color="rgb(123,116,110)">
                    All guests at La Villa Bleue will have the support of our
                    dedicated concierge team, who will be on-hand to help you
                    with recommendations and arrangements for any activities,
                    tours, or day trips. We work with trusted service providers
                    who we know provide our guests with fantastic, tailored
                    experiences – every time.
                  </P>
                  <P align="center" color="rgb(123,116,110)">
                    Experience the simplicity and ease of time spent together
                    with friends and family, with our team dedicated to ensuring
                    your sensational stay.
                  </P>
                </Col>
              </Col>
            </Content>
          </Section>
          <Section>
            <div
              style={{
                height: "400px",
                padding: "50px 0",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                autoPlay
                muted
                loop
                controls={false}
                style={{
                  minWidth: "100%",
                  position: "fixed",
                  top: 0,
                  zIndex: "-1",
                }}
              >
                <source src={vid} type="video/mp4" />
              </video>
              <img src={Logo} style={{ width: "150px", height: "auto" }} />
            </div>
          </Section>
          <Section bg="#fff">
            <Content>
              <Col align="start">
                <H2 align="left">
                  DISCOVER <Line align="start" />
                </H2>
                <H3 align="left">MEDVIDINA BAY</H3>
                <Col align="start">
                  <P align="left" color="rgb(123,116,110)">
                    This is the special place where you will find La Villa
                    Bleue. Nestled away in the picture-perfect coastline of the
                    south side of Hvar, between the traditional villages of
                    Gdinj and Zastražiš
                    <span style={{ fontSize: "14px" }}>ć</span>e, is Medvidina
                    Bay. After turning off the main road that spans the width of
                    the island, you will journey down a 4-kilometre gravel road,
                    and find just four houses in one of the quietest yet still
                    accessible bays on the island. With three secluded,
                    sparkling beaches to enjoy and an unimpeded view of the
                    stars at night, this is the perfect place to escape it all
                    and just relax, recharge, and enjoy the blessings of the
                    natural world.
                  </P>
                  <P align="left" color="rgb(123,116,110)">
                    It is a stunningly remote and secluded location, which
                    creates a truly unique and novel experience for many of our
                    guests.
                  </P>
                </Col>
                <Btn
                  border="1px solid #f5a913"
                  color="#7b746e"
                  width="358px"
                  hbgcolor="#f5a913"
                  hcolor="#fff"
                  style={{ marginTop: "30px" }}
                  onClick={() => redirect("/villa")}
                >
                  <span>EXPLORE THE VILLA</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section>
            <MapBox />
          </Section>
          <Section bg="#fff">
            <Content>
              <Col align="end">
                <H2 align="right">
                  HOW TO GET THERE? <Line align="end" />
                </H2>
                <H3 align="right">DIRECTIONS ; DISTANCES</H3>
                <Col align="end">
                  <P align="left" color="rgb(123,116,110)">
                    La Villa Bleue is located in Medvidina Bay on the south side
                    of Hvar island, roughly in the centre.
                  </P>
                  <P align="left" color="rgb(123,116,110)">
                    Hvar island is connected to the mainland and other islands
                    through four harbours, only two of which allow for cars
                    (Stari Grad and Drvenik). The usual route to the villa
                    includes arrival by plane to Split airport, a short car ride
                    to the ferry port, and then taking a ferry to the island,
                    arriving at Stari Grad ferry port. Alternatively, you can
                    take a ferry from Drvenik port on the mainland to Su
                    <span style={{ fontSize: "14px" }}>ć</span>uraj on the far
                    east of the island. The driving distances from either port –
                    Stari Grad or Su
                    <span style={{ fontSize: "14px" }}>ć</span>uraj – are
                    approximately the same, around 40 minutes.
                  </P>
                  <P align="left" color="rgb(123,116,110)">
                    Many of our European guests also choose to drive the entire
                    distance from their home location to Split or Drvenik to
                    take a ferry to the island.
                  </P>
                  <P align="left" color="rgb(123,116,110)">
                    Hvar town, a 1-hour drive from La Villa Bleue, is well
                    served with regular passenger-only catamarans to and from
                    Split, as well as onward boats to Kor
                    <span style={{ fontSize: "14px" }}>č</span>ula daily, and
                    Vis once a week.
                  </P>
                  <P align="left" color="rgb(123,116,110)">
                    Once your stay is booked, we can help you to arrange the
                    ideal arrival plan based on your specific circumstances.
                    Full driving directions and navigation guidance will be
                    provided in advance of your stay.
                  </P>
                  <P align="left" color="rgb(123,116,110)">
                    Please note that you will need a vehicle in order to access
                    the villa. A 4x4 is preferred by some guests for the gravel
                    road but is not a necessity. Most guests choose to rent a
                    vehicle upon arrival to Split, but if you would prefer to
                    rent a car upon arrival to the island and need assistance or
                    recommendations, please contact us.
                  </P>
                </Col>
                <Btn
                  border="1px solid rgba(123, 116, 110, 0.78)"
                  bgcolor="rgba(123, 116, 110, 0.78)"
                  width="358px"
                  hborder="1px solid #f5a913"
                  hbgcolor="transparent"
                  hcolor="rgba(123, 116, 110, 0.78)"
                  style={{ marginTop: "30px" }}
                  onClick={() => redirect("/booking")}
                >
                  <span>HAVE A QUESTION </span>
                  <MdOutlineEmail
                    style={{ fontSize: "20px", marginBottom: "2px" }}
                  />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section bg="#fff">
            <img src={air} style={{ width: "100%", height: "auto" }} />
          </Section>
          <Footer type="full" />
        </>
      ) : (
        <Menu setOpen={setOpen} />
      )}
    </Layout>
  );
}
